// Fonts
$primary-font: 'proxima-nova', sans-serif;
$secondary-font: 'proxima-nova', sans-serif;

// Colors
$primary-color: #d52a89;
$secondary-color: #fff;
$black: #71706F;
$white: #fff;
$gray: #404041;
$dark-gray: #3f3f42;
$light-gray: #f0f0f0;
$orange: $black;

// Media Query
$breakpoint-sm: 540px;
$breakpoint-md: 720px;
$breakpoint-lg: 960px;
$breakpoint-xl: 1140px;