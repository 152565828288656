.nav-link {
	text-transform: lowercase;
	font-size: 1.25rem;
	color: #fff !important;
	&:hover {
		color: $primary-color !important;
}
}
@media(max-width: 992px) {
	nav .nav-item, .mobile-close {
		font-size: 2rem;
	}
}
.navbar-toggler {
	border: none;
	padding: 0 !important	;
}

/** Slide in Nav **/
.navbar-collapse {
	position: fixed;
	top: 0px;
	background: $primary-color;
	left: 100%;
	padding: 15px;
	width: 100%;
	transition: all 0.4s ease;
	z-index: 1000;
	height: 100%;
	display: block;
	@media(min-width: 992px) {
		position: relative;
		left: initial;
		background: none;
		.navbar-nav {
			margin: 0;
		}
	}
}

.navbar-dark .navbar-nav .nav-link a {
    color: #fff !important;
}
.bg-primary .nav-link:hover {
	color: #000 !important;
}
.mobile-close {
	border: none;
	text-align: right;
	background: none;
	color: white;
}
.navbar-collapse.collapsing {
	height: 100% !important;
	margin-left: 50%;
	left: 50%;
	transition: all 0.2s ease;
}
.navbar-collapse.show {
	left: 0;
}
