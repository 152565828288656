h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $secondary-font;
	line-height: 1;
}
body {
	font-family: $primary-font;
	color: $black;
}
@media(min-width: 1px) {
	p {
		font-size: 1.25rem;
	}
}
.bg-dark {
	background-color: $dark-gray !important;
}
a {
	color: $black;
	font-family: $secondary-font;
	&:hover {
		color: rgba($black, 0.7);
		text-decoration: none;
	}
}
@media(max-width: 500px) {
	.carousel-item {
		min-height: 350px;
	}
}
.hero-image {
	background-image: url('../img/gng-home-hero.jpg');
	background-size: cover;
	height: 80vh;
	background-position: center;
	position: relative;
	img {
		position: absolute;
		bottom: 0;
	}
}
.social-icons {
	width: 3rem;
}
.line-1quarter {
	line-height: 1.25;
}
.line-3rem {
	line-height: 3rem;
}
.line-4rem {
	line-height: 4rem;
}
.line-5rem {
	line-height: 5rem;
}
.py-6 {
	padding-bottom: 8rem;
	padding-top: 8rem;
}
.btn-circle {
	width: 200px;
	height: 200px;
	border: 10px solid #fafafa;
}

@media(max-width: 500px) {
	.btn-circle {
		width: 150px;
		height: 150px;
	}
}
a {
	&.bg-primary {
		&:hover {
			background-color: rgba($primary-color, 1) !important;
			color: #fff !important;
		}
	}
}
.carousel-inner {
	width: 75%;
}
.woocommerce-result-count {
	display: none;
}
.bg-dark-faded {
	background-color: rgba(0,0,0, 0.5);
}
.bg-light-gray {
	background-color: $light-gray;
}
.bg-primary {
	background: $primary-color !important;
}
.text-primary {
	color: $primary-color !important;
}
.btn-primary {
	background-color: $primary-color;
	border-radius: 50px;
	border: none;
	font-weight: 700;
	padding: 1rem 3rem;
	&:hover, &:focus, &:active {
		background-color: rgba($primary-color, 0.7) !important;
	}
}
.wave:before {
	content: "";
	height: 130px;
	width: 100%;
	position: relative;
	display: inline-block;
	margin-top: -105px;
	background-position: top;
}
.primary-wave{
	&:before {
		//background-image: url('../img/flow_shape_footer.png');
		background-image: url('../img/wave_graphics-03.png');
	}
}
.gray-wave:before {
	//background-image: url('../img/flow_shape_3.png');
	background-image: url('../img/wave_graphics-01.png');
}
@media(max-width: 750px) {
	.wave:before {
		background-size: contain;
		height: 42px;
	}
}
.negative-header {
	position: relative;
	z-index: 1000;
	margin-bottom: -120px;
}
.wpcf7-response-output {
	display: none;
}
.bg-light {
	background: $light-gray !important;
}
.w-lg-100 {
	@media(min-width: 960px) {
		width: 100%;
	}
}
.carousel-item img {
	min-height: 60vh;
	width: 100%;
	object-fit: cover;
	object-position: right;
	filter: brightness(.7);
}
.carousel-caption {
	//background: rgba($black, 0.7);
}
#back-to-top {
	position: fixed;
	bottom: 1rem;
	right: 0.5rem;
	color: #ffffff;
	font-size: 1.5rem;
	line-height: 2.5rem;
	text-align: center;
	background: $black;
	opacity: 0;
	border: 2px solid #ddd;
	border-radius: 50%;
	height: 3rem;
	width: 3rem;
	z-index: 9999;
	transition: all .2s ease-in-out;
	&:hover {
		background-color: rgba($black, 0.7);
	}
}
.bg-fixed {
	@media(min-width: 540px) {
		background-attachment: fixed;
	}
}
.show {
	opacity: 1 !important;
}
@media(min-width: 1000px) {
	.cart_totals {
		width: 75% !important;
	}
}

#stripe-payment-data {

	#wc-stripe-cc-form {

		.form-row {
			display: flex;
			flex-direction: column;

			.wc-stripe-elements-field {
				max-height: 2rem;
			}

		}

	}

	.woocommerce-SavedPaymentMethods-saveNew {
		display: inherit;
	}

}
div#wc-stripe-payment-request-wrapper, #wc-stripe-payment-request-button-separator {
	display: none !important;
}

.bg-grey {
	background-color: #9fa2a7;
}
.rounded-top {
	border-radius: 30px 30px 0 0 !important;
}
.rounded-bot {
	border-radius: 0 0 30px 30px !important;
}
input[type="text"], input[type="tel"], input[type="email"], textarea {
	border: 1px solid !important;
	border-radius: 20px !important;
	padding: 0rem 1rem !important;
}
.hero-franchise {
	background-image: url(../img/franchise_header_NEW.jpg);
	@media(max-width: 540px) {
		height: 60vh;
	}
}
.hero-about {
	background-image: url(../img/about_header_NEW.jpg);
	@media(max-width: 540px) {
		height: 60vh;
	}
}
.negative-header {
	margin-bottom: -108px;
}
.z-10 {
	z-index: 10;
}
.pb-footer {
	padding-bottom: 125px !important;
}
li.product {
	text-align: center;
	@media(max-width: 540px) {
		border-bottom: 1px solid black;
	}
}
/*************** Wordpress Store Finder ******************/
/*********************************************************/
/* Resets */
label[for="wpsl-search-input"], label[for="wpsl-results-dropdown"], label[for="wpsl-radius-dropdown"], label[for="wpsl-results-dropdown"] {
	width: fit-content !important;
	margin-top: 5px !important;
}
.wpsl-dropdown, #wpsl-search-input {
	margin-right: 1.5rem !important;
}
#wpsl-search-wrap {
	width: fit-content;
}
.wpsl-geolocation-run {
	display: flex;
	justify-content: center;
}
.wpsl-dropdown {
	border-radius: 25px;
	border: 1px solid #000;
}
input#wpsl-search-input {
	height: 2.5rem;
}
.wpsl-directions {
	margin-top: 1rem;
}

/* Button Styling + Turn Directions into a Button */
#wpsl-search-btn, .wpsl-directions {
	background: $primary-color;
	border: 0px !important;
	border-radius: 25px !important;
	padding: 0.5rem 2rem;
	color: $white;
}





